import {
  takeEvery,
  CallEffect,
  delay,
  call,
  put,
  PutEffect,
  SelectEffect,
  select,
  take,
  TakeEffect,
  takeLatest,
  all
} from 'redux-saga/effects';
import {
  GENERATE_IMAGES,
  imageGenerationSuccess,
  imageGenerationFailed,
  REGENERATE_IMAGES,
  ADD_GENERATED_IMAGE_TO_PROJECT,
  AddGeneratedImageToProjectAction,
  TRY_NEW_IMAGE_PROMPT,
  resetImageGeneration,
  GENERATE_VOICEOVER,
  voiceoverGenerationSuccess,
  voiceoverGenerationFailed,
  changeAiPanelStep,
  fetchScriptSecondsGeneratedSuccess,
  GenerateScriptSuccessAction,
  GENERATE_SCRIPT_SUCCESS,
  generateScriptSuccess,
  generateScriptFailed,
  GENERATE_SCRIPT,
  SAVE_GENERATED_SCRIPT,
  SaveGeneratedScriptAction,
  TryNewScriptPromptAction,
  TRY_NEW_SCRIPT_PROMPT,
  resetScriptGeneration
} from 'js/actionCreators/betaAiFeaturesActions';
import { showError, showLeftHandPanel } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { FILE_CONTENT_TYPES, IMAGE_GEN_STYLES, IMAGE_PROVIDER_TYPES } from 'js/config/consts';
import {
  RootState,
  ImageGenerationInputs,
  HTTPError,
  ImageGenerationProgressApiData,
  ImageGenerationStatus,
  ImageGenerationApiData,
  VoiceoverGenerationApiData,
  VoiceoverGenerationProgressApiData,
  VoiceoverGenerationStatus,
  VoiceoverGenerationInputs,
  VSCAssetImageSourceName,
  AudioSource,
  InputVoiceData,
  BetaAiPanelTab,
  BetaAiImageGenerationTabStep,
  ScriptGenerationInputs,
  BetaAiScriptGenerationTabStep,
  BetaAiVoiceGenerationTabStep,
  ScriptGenerationApiData,
  GeneratedScene,
  LeftHandPanel,
  LIMIT_TYPES
} from 'js/types';
import {
  PROCESS_AI_IMAGES_FAILURE,
  PROCESS_AI_IMAGES_SUCCESS,
  ProcessAiImagesFailureAction,
  processAiImagesRequest,
  ProcessAiImagesSuccessAction
} from 'js/actionCreators/imageProcessingActions';
import { addUserImageToScribe } from 'js/actionCreators/imagesActions';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import {
  saveAudioToLibrary,
  SAVE_AUDIO_TO_LIBRARY_FAILED,
  SAVE_AUDIO_TO_LIBRARY_SUCCESS,
  SaveAudioToLibrarySuccessAction,
  SaveAudioToLibraryFailedAction
} from 'js/actionCreators/audioActions';
import { ALLOWED_AUDIO_MIME_TYPE, AI_FEATURES } from 'js/config/config';
import {
  urlToFileRequest,
  URL_TO_FILE_SUCCESS,
  URL_TO_FILE_FAILURE,
  UrlToFileSuccessAction,
  UrlToFileFailureAction
} from 'js/actionCreators/urlToFileActions';
import { syncScript } from 'js/actionCreators/projectScriptActions';
import { estimateTextLengthInSeconds } from 'js/editor/AiPanel/helpers/generationDurationEstimate';
import OPTIONS from 'js/editor/AiPanel/VoiceGeneration/options.json';
import { decrementLimit } from 'js/actionCreators/limitActions';

import { mapImageGenerationFailedReasonToMessage } from './sagaHelpers/mapImageGenerationFailedReasonToMessage';
import { getViewportData } from './sagaHelpers/getViewportData';
import { uploadImageAssetWithRetries } from './imageUploadSagas';

const SCRIPT_SECONDS_GENERATED_APPDATA_KEY = 'script-generations';

export const getImageGenerationInputs = (state: RootState) => state.betaAiFeatures.imageGenerationInputs;
export const getImageGenerationResults = (state: RootState) => state.betaAiFeatures.imageGenerationResults;
export const getVoiceoverGenerationInputs = (state: RootState) => state.betaAiFeatures.voiceoverGenerationInputs;
export const getVoiceoverGenerationResults = (state: RootState) => state.betaAiFeatures.voiceoverGenerationResults;
export const getScriptGenerationInputs = (state: RootState) => state.betaAiFeatures.scriptGenerationInputs;

type UploadImageAssetWithRetriesReturnType =
  | { assetId: number; signedImgUrl: string; error: undefined }
  | { assetId: undefined; signedImgUrl: undefined; error: Error };

const DELAY_MS = 1000;
const MAX_RETRIES = 3;
const IMAGE_FILENAME = 'AI Generated Image';
const ERROR_MESSAGE = 'Failed to generate image';

function* handleImageGenerationProgress(executionId: string) {
  let isDone = false;

  while (!isDone) {
    const response = (yield call(
      appServices.fetchImageGenerationProgress,
      executionId
    )) as ImageGenerationProgressApiData;

    if (response.status === ImageGenerationStatus.IN_PROGRESS) {
      yield delay(DELAY_MS);
      continue;
    }

    isDone = true;

    if (response.status === ImageGenerationStatus.COMPLETE) {
      return response;
    }

    const failedMessage = mapImageGenerationFailedReasonToMessage(response.failedReason);
    throw new Error(failedMessage);
  }
}

function* handleImageProcessing(response: ImageGenerationProgressApiData, executionId: string) {
  if (response.status !== ImageGenerationStatus.COMPLETE) return;

  const images = response.signedUrls.map(url => ({ url }));
  yield put(processAiImagesRequest({ images }));

  const processedImagesResult = (yield take([PROCESS_AI_IMAGES_SUCCESS, PROCESS_AI_IMAGES_FAILURE])) as
    | ProcessAiImagesSuccessAction
    | ProcessAiImagesFailureAction;

  if ('error' in processedImagesResult) throw processedImagesResult.error;

  const uploadPromises = processedImagesResult.images.map(image =>
    call(uploadImageAssetWithRetries, {
      file: image.blob,
      filename: IMAGE_FILENAME,
      source: VSCAssetImageSourceName.AI,
      maxRetries: MAX_RETRIES,
      sourceIdentifier: executionId
    })
  );

  const uploadResults = (yield all(uploadPromises)) as UploadImageAssetWithRetriesReturnType[];

  if (uploadResults.some(result => result.error)) throw new Error('Failed to upload image assets');

  const validUploadResults = uploadResults.filter(
    (result): result is { assetId: number; signedImgUrl: string; error: undefined } =>
      result.assetId !== undefined && result.signedImgUrl !== undefined
  );

  const uploadedAssets = validUploadResults.map(result => ({
    assetId: result.assetId,
    url: result.signedImgUrl
  }));

  yield put(decrementLimit(LIMIT_TYPES.IMAGE_GENERATIONS, 1));
  yield put(imageGenerationSuccess(uploadedAssets, response.id, response.metadata.enhancedPrompt));
}

export function* generateImages(): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  ImageGenerationApiData | ImageGenerationProgressApiData | ImageGenerationInputs
> {
  const { style, prompt } = (yield select(getImageGenerationInputs)) as ImageGenerationInputs;
  const model = IMAGE_GEN_STYLES[style];

  try {
    const { executionId } = (yield call(
      appServices.generateImages,
      model,
      prompt,
      AI_FEATURES.IMAGE.GENERATION_QUANTITY
    )) as ImageGenerationApiData;
    const response = (yield call(handleImageGenerationProgress, executionId)) as ImageGenerationProgressApiData;
    yield call(handleImageProcessing, response, executionId);
  } catch (error) {
    if (error instanceof Error) {
      yield put(imageGenerationFailed(`${ERROR_MESSAGE}: ${error.message}`));
      yield put(showError({ message: ERROR_MESSAGE, description: error.message }));
      yield put(changeAiPanelStep(BetaAiPanelTab.IMAGE_GENERATION, BetaAiImageGenerationTabStep.OVERVIEW));
    }
  }
}

export function* regenerateImages(): Generator<SelectEffect | PutEffect, void, ImageGenerationInputs> {
  const { style, prompt } = yield select(getImageGenerationInputs);
  yield put({
    type: GENERATE_IMAGES,
    style: style,
    prompt: prompt
  });
}

function* addGeneratedImageToProject({
  assetId,
  project,
  rating,
  eventTrigger
}: AddGeneratedImageToProjectAction): Generator<CallEffect | PutEffect, void, Blob> {
  yield put(
    addUserImageToScribe(
      assetId,
      FILE_CONTENT_TYPES.SVG,
      project.id,
      getViewportData(project.canvasSize),
      { title: 'AI Image Generator Panel' },
      'AI Generated Image',
      IMAGE_PROVIDER_TYPES.AI_LIBRARY,
      rating,
      eventTrigger,
      VSCAssetImageSourceName.AI
    )
  );
}

function* fetchScriptSecondsGenerated(
  retry = false
): Generator<CallEffect | PutEffect, void, { scriptSecondsGenerated: number }> {
  try {
    const result = yield call(
      { fn: appServices.getAppData, context: appServices },
      SCRIPT_SECONDS_GENERATED_APPDATA_KEY
    );
    yield put(fetchScriptSecondsGeneratedSuccess(result.scriptSecondsGenerated));
  } catch (error) {
    const err = error as HTTPError;
    if ('httpStatusCode' in err && err.httpStatusCode === 404 && !retry) {
      // Initialize the count
      yield call({ fn: appServices.setAppData, context: appServices }, SCRIPT_SECONDS_GENERATED_APPDATA_KEY, {
        scriptSecondsGenerated: 0
      });
      yield call(fetchScriptSecondsGenerated, true);
      return;
    }

    console.error('Failed to fetch script seconds generated', error);
    yield put(showError({ message: 'Failed to fetch script seconds generated', description: err.message }));
  }
}

function* incrementScriptSecondsGenerated({
  script
}: GenerateScriptSuccessAction): Generator<SelectEffect | CallEffect | PutEffect, void, number> {
  try {
    const scriptSecondsGenerated = yield select((state: RootState) => state.betaAiFeatures.scriptSecondsGenerated) ?? 0;
    yield call({ fn: appServices.setAppData, context: appServices }, SCRIPT_SECONDS_GENERATED_APPDATA_KEY, {
      scriptSecondsGenerated: scriptSecondsGenerated + estimateTextLengthInSeconds(script)
    });
    yield call(fetchScriptSecondsGenerated);
  } catch (err) {
    const error = err as Error;
    console.error('Failed to increment voiceover seconds generated', error);
    yield put(showError({ message: 'Failed to increment script seconds generated', description: error.message }));
  }
}

function* getGenerationsCounts() {
  yield call(fetchScriptSecondsGenerated);
}

function* tryNewImagePrompt(): Generator<PutEffect, void, void> {
  yield put(resetImageGeneration());
  yield put(changeAiPanelStep(BetaAiPanelTab.IMAGE_GENERATION, BetaAiImageGenerationTabStep.OVERVIEW));
}

function* callGenerateVoiceover(
  prompt: string,
  locale: string,
  voice: InputVoiceData
): Generator<CallEffect, VoiceoverGenerationApiData, VoiceoverGenerationApiData> {
  return yield call(appServices.generateVoiceover, prompt, locale, voice.gender, voice.originalName);
}

function* callFetchVoiceoverProgress(
  executionId: string
): Generator<CallEffect, VoiceoverGenerationProgressApiData, VoiceoverGenerationProgressApiData> {
  return yield call(appServices.fetchVoiceoverGenerationProgress, executionId);
}

function* handleVoiceoverGenerationSuccess(
  response: VoiceoverGenerationProgressApiData,
  prompt: string
): Generator<
  CallEffect | PutEffect | TakeEffect,
  void,
  SaveAudioToLibrarySuccessAction | SaveAudioToLibraryFailedAction | UrlToFileSuccessAction | UrlToFileFailureAction
> {
  if (response.status !== VoiceoverGenerationStatus.SUCCEEDED) return;

  yield put(urlToFileRequest({ url: response.url, filename: prompt, mimeType: ALLOWED_AUDIO_MIME_TYPE }));
  const processResourceResult = (yield take([URL_TO_FILE_SUCCESS, URL_TO_FILE_FAILURE])) as
    | UrlToFileSuccessAction
    | UrlToFileFailureAction;

  if ('error' in processResourceResult) throw processResourceResult.error;

  yield put(saveAudioToLibrary(processResourceResult.file, AudioSource.AI_GENERATED));

  const saveVoiceoverResult = (yield take([SAVE_AUDIO_TO_LIBRARY_FAILED, SAVE_AUDIO_TO_LIBRARY_SUCCESS])) as
    | SaveAudioToLibrarySuccessAction
    | SaveAudioToLibraryFailedAction;

  if ('error' in saveVoiceoverResult) throw saveVoiceoverResult.error;
  const audioClipData = saveVoiceoverResult.audioClipData;
  yield put(decrementLimit(LIMIT_TYPES.VOICEOVER_GENERATION_SECONDS, response.voiceoverSecondsUsed));
  yield put(
    voiceoverGenerationSuccess(
      response.url,
      response.id,
      audioClipData.projectAssetId,
      response.generatedFileDurationMs
    )
  );
}

export function* generateVoiceover(): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  VoiceoverGenerationApiData | VoiceoverGenerationProgressApiData | VoiceoverGenerationInputs
> {
  const { prompt, language, voice } = (yield select(getVoiceoverGenerationInputs)) as VoiceoverGenerationInputs;

  const languageOptions = OPTIONS.find(option => option.language === language);

  const selectedVoice = languageOptions?.voices.find(v => v.voice === voice.originalName);
  const selectedLocale = languageOptions?.locale;

  if (!selectedVoice || !selectedLocale) {
    yield put(showError({ message: `Voice not found`, description: 'Voice not found' }));
    return;
  }

  const transformedVoiceData = {
    gender: selectedVoice.gender,
    displayedName: selectedVoice.name,
    originalName: selectedVoice.voice
  };

  try {
    const { executionId } = (yield call(
      { fn: callGenerateVoiceover, context: appServices },
      prompt,
      selectedLocale,
      transformedVoiceData
    )) as VoiceoverGenerationApiData;

    let isDone = false;

    while (!isDone) {
      const response = (yield call(callFetchVoiceoverProgress, executionId)) as VoiceoverGenerationProgressApiData;

      if (response.status === VoiceoverGenerationStatus.PENDING) {
        yield delay(1000);
        continue;
      }

      isDone = true;

      if (response.status === VoiceoverGenerationStatus.SUCCEEDED) {
        yield call(handleVoiceoverGenerationSuccess, response, prompt);
        return;
      }

      const failedMessage =
        'There was an error with the server so we could not generate your voiceover. Please try again.';
      yield put(voiceoverGenerationFailed(failedMessage));
      yield put(showError({ message: `Failed to generate voiceover`, description: failedMessage }));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(voiceoverGenerationFailed('Failed to generate voiceover: ' + error.message));
      yield put(showError({ message: `Failed to generate voiceover`, description: error.message }));
      yield put(changeAiPanelStep(BetaAiPanelTab.VOICE_GENERATION, BetaAiVoiceGenerationTabStep.OVERVIEW));
    }
  }
}

const formatScenes = (scenes: GeneratedScene[]) => {
  return scenes.map((scene: GeneratedScene) => `Scene ${scene.scene} \n\n ${scene.narration}`).join('\n\n');
};

function* generateScript(): Generator<
  PutEffect | CallEffect | SelectEffect,
  void,
  ScriptGenerationApiData | ScriptGenerationInputs
> {
  yield put(changeAiPanelStep(BetaAiPanelTab.SCRIPT_GENERATION, BetaAiScriptGenerationTabStep.RESULTS));

  const {
    prompt,
    lengthOfScriptMinutes,
    lengthOfScriptSeconds,
    numberOfScenes,
    predefinedStyles,
    customStyle
  } = (yield select(getScriptGenerationInputs)) as ScriptGenerationInputs;

  try {
    const lengthInSeconds = lengthOfScriptMinutes * 60 + lengthOfScriptSeconds;
    const styles = [
      ...predefinedStyles.filter(style => style.isChecked).map(style => style.label),
      ...(customStyle ? [customStyle] : [])
    ];
    const response = (yield call(
      appServices.generateScript,
      prompt,
      lengthInSeconds,
      numberOfScenes,
      styles
    )) as ScriptGenerationApiData;

    if (response.policyViolation) {
      throw new Error(
        'The script contains content that violates our policies. Please try again with a different prompt.'
      );
    }
    const script = formatScenes(response.scenes);

    yield put(generateScriptSuccess(script));
  } catch (error) {
    if (error instanceof Error) {
      yield put(showError({ message: `Failed to generate script`, description: error.message }));
      yield put(changeAiPanelStep(BetaAiPanelTab.SCRIPT_GENERATION, BetaAiScriptGenerationTabStep.OVERVIEW));
      yield put(generateScriptFailed(error));
    }
  }
}

function* saveGeneratedScript(action: SaveGeneratedScriptAction) {
  const { script, projectId } = action;
  yield put(syncScript(projectId, script));
  yield put(showLeftHandPanel(LeftHandPanel.SCRIPTS));
}

function* tryNewScriptPrompt(action: TryNewScriptPromptAction) {
  if (action.reset) {
    yield put(resetScriptGeneration());
  }
  yield put(changeAiPanelStep(BetaAiPanelTab.SCRIPT_GENERATION, BetaAiScriptGenerationTabStep.OVERVIEW));
}

export default function* betaAiFeaturesSagas() {
  yield takeEvery(GENERATE_IMAGES, generateImages);
  yield takeEvery(REGENERATE_IMAGES, regenerateImages);
  yield takeEvery(ADD_GENERATED_IMAGE_TO_PROJECT, addGeneratedImageToProject);
  yield takeLatest(AUTH_SUCCESS, getGenerationsCounts);
  yield takeEvery(TRY_NEW_IMAGE_PROMPT, tryNewImagePrompt);
  yield takeEvery(GENERATE_VOICEOVER, generateVoiceover);
  yield takeEvery(GENERATE_SCRIPT, generateScript);
  yield takeEvery(GENERATE_SCRIPT_SUCCESS, incrementScriptSecondsGenerated);
  yield takeEvery(SAVE_GENERATED_SCRIPT, saveGeneratedScript);
  yield takeEvery(TRY_NEW_SCRIPT_PROMPT, tryNewScriptPrompt);
}
