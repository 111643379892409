import {
  DECREMENT_LIMIT,
  DecrementLimitAction,
  UPDATE_LIMITS,
  UpdateLimitsAction
} from 'js/actionCreators/limitActions';
import { Limits, LIMIT_TYPES } from 'js/types';

const initialState: Limits = {
  [LIMIT_TYPES.DOWNLOADS]: {
    count: 0,
    resetDate: '',
    limit: 0,
    limitUpgrades: []
  },
  [LIMIT_TYPES.IMAGE_GENERATIONS]: {
    count: 0,
    resetDate: '',
    limit: 0,
    limitUpgrades: []
  },
  [LIMIT_TYPES.VOICEOVER_GENERATION_SECONDS]: {
    count: 0,
    resetDate: '',
    limit: 0,
    limitUpgrades: []
  }
};

type LimitActions = UpdateLimitsAction | DecrementLimitAction;

export default function limitReducer(state = initialState, action: LimitActions): Limits {
  switch (action.type) {
    case UPDATE_LIMITS:
      return { ...state, ...action.limits };
    case DECREMENT_LIMIT:
      return {
        ...state,
        [action.limitType]: {
          ...state[action.limitType],
          count: state[action.limitType].count + action.decrementAmount
        }
      };
    default:
      return state;
  }
}
